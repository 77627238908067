var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main"},[(_vm.hasAgreements)?_c('div',{staticClass:"card-list-wrapper"},[_c('ul',_vm._l((_vm.agreements),function(t){return _c('li',{key:t.cardUserId,staticClass:"cards"},[_c('router-link',{staticClass:"link",attrs:{"to":{
            name: 'agreement-detail',
            params: { id: t.customerAgreementId },
          }}},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-container"},[_c('div',{staticClass:"text-section"},[_c('div',[_c('VueText',{attrs:{"color":"grey-30","sizeLevel":"3","weightLevel":"3"}},[_vm._v("Tarihler")])],1),_c('div',[_c('VueText',{attrs:{"color":"grey-40","sizeLevel":"3","weightLevel":"3"}},[_vm._v(_vm._s(_vm._f("parseISODate")(t.customerAgreementStartDate,t.customerAgreementEndDate)))])],1)])]),_c('div',{staticClass:"card-status",class:[
                t.customerAgreementStatus === 'Started' || t.customerAgreementStatus === 'Opened'
                  ? 'continue'
                  : t.customerAgreementStatus === 'Unknown'
                  ? 'unknown'
                  : 'finish' ]},[_c('VueText',{attrs:{"color":"white-100","sizeLevel":"4","weightLevel":"4"}},[_vm._v(_vm._s(_vm._f("convertText")(t.customerAgreementStatus)))])],1)])])],1)}),0)]):_c('div',{staticClass:"warning-center"},[_c('VueText',{staticClass:"warning-card",attrs:{"color":"gray-800","sizeLevel":"5","weightLevel":"2"}},[_vm._v(" Kriteriniz Bulunmamaktadır. ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }