import { render, staticRenderFns } from "./Agreements.vue?vue&type=template&id=0fa83336&scoped=true&"
import script from "./Agreements.vue?vue&type=script&lang=js&"
export * from "./Agreements.vue?vue&type=script&lang=js&"
import style0 from "./Agreements.vue?vue&type=style&index=0&id=0fa83336&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0fa83336",
  null
  
)

export default component.exports